.pt-md-62 {
	padding-top: 62px;
}

.CollapsibleHeader-collapse img {
	max-height: 46px;
}

.PageBody {
	margin-top: 140px;
}

#header {
	background-color: rgb(221, 221, 221);
}

#CurrentConditionsAtAGlanceDetail {
	float: right;
}

.CurrentConditionsAtAGlanceDetail-wrapper {
	display: inline-block;
	vertical-align: middle;
}

#CurrentConditionsAtAGlanceDetail-weatherInfo {
	display: inline-block;
	vertical-align: middle;
}

.CurrentConditionsAtAGlanceDetail-wrapper-cityName {
	width: 150px;
	font-weight: 700;
}

.CurrentConditionsAtAGlanceDetail-wrapper-sky {
	font-weight: 400;
}

.CurrentConditionsAtAGlanceDetail-temp {
	font-size: 36px;
	vertical-align: middle;
	font-weight: 700;
}

.CurrentConditionsAtAGlanceDetail-temp--condensed {
	font-size: 24px;
	vertical-align: middle;
	font-weight: 700;
}

.weather-icon {
	background-size: contain;
	height: 50px;
	width: 50px;
	display: inline-block;
	vertical-align: middle;
}

.weather-icon--condensed {
        background-size: contain;
        height: 24px;
        width: 24px;
        display: inline-block;
        vertical-align: middle;
}

.CollapsibleHeader-topHeader,
.CollapsibleHeader-HeaderMenu {
	width: 100%;
	float: right;
}

.CollapsibleHeader-topHeader--collapse { 
	display: inline-block;
	width: auto;
	float: right;
}

.CollapsibleHeader-HeaderMenu--collapse {
	float: none;
	display: block;
	width: auto;
}

.CollapsibleHeader-bottomHeader--collapse {
	display: none;
}

#CollapsibleHeader-toggleIcon {
	padding: 0px;
	border: 0px;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.CurrentConditionsAtAGlanceDetail-wrapper--collapse {
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
}

.CurrentConditionsAtAGlanceDetail-wrapper-cityName--collapse {
	width: 85px;
	font-weight: 700;
}

.CardContainer {
	display: inline-block;
}

.CardContainer-gutterSpacing--sm {
	float: left;
	padding: 4px;
}

.Card-textContainer {
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.65);
}

.Card-full-container--sm {
	border: 0px;
	overflow: hidden;
	height: 180px;
	border-radius: 0px;
}

.Card-full-container--lg {
	border: 0px;
	overflow: hidden;
	height: 368px;
	border-radius: 0px;
}

.imageContainer {
	border-radius: 0px !important;
}

.Card-full-title--lg {
	font-weight: 500;
	font-size: 32px;
	line-height: 1.14;
	max-height: 105px;
}

.Card-full-title--sm {
	font-weight: 500;
	font-size: 23px;
	line-height: 1.12;
	max-height: 75px;
	overflow: hidden;
}

.Card-full-description {
	font-size: 21px;
	line-height: 1.18;
	max-height: 124px;
	overflow: hidden;
}

@media (max-width: 991.98px){
	.PageBody {
		margin-top: 200px;
	}

	.CollapsibleHeader-HeaderMenu {
		display: none;
	}
}

@media (min-width: 992px) {
	.CollapsibleHeader-HeaderMenu {
		display: block;
	}
}

@media (max-width: 768px){
	#header {
		position: static !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	#header .container-md {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	#CollapsibleHeader-logo {
		padding-right: 0px;
	}

	#CollapsibleHeader-logo img {
		max-height: 50px !important;
	}

	#HeaderMenu {
		padding-left: 0px;
		text-align: right;
	}

	.PageBody {
		margin-top: 10px;
	}

	.pt-md-62 {
		padding-top: 0px;
	}

	.CurrentConditionsAtAGlanceDetail-wrapper {
		display: inherit;
	}

	.CurrentConditionsAtAGlanceDetail-wrapper-cityName {
		width: 115px;
		font-size: 20px;
	}

	.CurrentConditionsAtAGlanceDetail-temp {
		font-size:24px;
	}

	.weather-icon {
		width: 24px;
		height: 24px;
	}

	.CollapsibleHeader-topHeader {
		display: inline-block;
		vertical-align: top;
		float: none;
		width: calc(14vw);
	}

	.CollapsibleHeader-HeaderMenu {
		display: inline-block;
		vertical-align: top;
		float: none;
		width: auto;
	}
}
